/**
Demonstrate CSS Variables
- consider moving from SASS completely
 */
/*
@font-face {
  font-family: 'Moonshiner-Regular';
  src: url('../assets/fonts/Moonshiner/Moonshiner-Regular.ttf.woff') format('woff'),
  url('../assets/fonts/Moonshiner/Moonshiner-Regular.ttf.svg#Moonshiner-Regular') format('svg'),
  url('../assets/fonts/Moonshiner/Moonshiner-Regular.ttf.eot'),
  url('../assets/fonts/Moonshiner/Moonshiner-Regular.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
*/

:root {
  /*************
   PALETTE
   *************/
  --color-white: #fff;
  --color-black: #162343; /* body text */

  --color-blue-dark: #07205d;
  --color-blue: #002f6d; /* headers */
  --color-blue-light: #3a84df; /* links */

  --color-yellow: #f5ee42;
  --color-secondkit: #ff8e7c;
  --color-green: #86ab4d;

  --color-gray-dark: #402020;
  --color-gray: rgba(64, 64, 64, 0.6);
  --color-gray-lite: rgba(64, 64, 64, 0.3);
  --color-gray-liter: rgba(0, 0, 0, 0.05);

  --color-red: var(--color-secondkit);

  --text-color: var(--color-black);
  --text-color-white: var(--color-white);
  --link-color: #00019e;
  --link-color-hover: #296dbb;

  --color-error: var(--color-red);
  --color-error-text: var(--color-red);

  --color-info: var(--color-green);
  --color-info-text: var(--text-color);

  --color-warning: var(--color-yellow);
  --color-warning-text: var(--text-color);

  --color-background: var(--color-white);

  /*************
  TYPOGRAPHY
  *************/
  --font-base: 'Open Sans', sans-serif;
  --font-header: 'Asul', --font-base;

  --font-size-h1: 4.1rem;
  --font-size-h2: 3.6rem;
  --font-size-h3: 2.9rem;
  --font-size-h4: 1.7rem;
  --font-size-h5: 1.4rem;
  --font-size-h6: 1.1rem;

  --line-height-h1: 1.3em;
  --line-height-h2: 1.3em;
  --line-height-h3: 1.3em;

  /*************
  APP / CONTAINERS
  *************/
  --max-width: 121rem; /* was 1600px */
  --side-padding: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-header);
  color: var(--color-blue-dark);
  font-weight: 300;
  font-style: normal;
  text-transform: none;
  text-decoration: none;
  padding-bottom: 1rem;
}

h1,
h2,
h3 {
  font-weight: 600;
}

h1 {
  margin-bottom: 1rem;
  padding: 0;
  font-size: var(--font-size-h1);
  line-height: var(--line-height-h1);
  letter-spacing: 0.14rem;
  text-shadow: 0.02em 0.05em 0.03em #d9d9d9;
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--line-height-h2);
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--line-height-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

p {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin: 0;
  padding: 0;
}

a {
  color: var(--link-color);
  font-weight: 700;
  text-decoration: underline;
}

a:hover,
a:focus {
  text-decoration: none;
}

a:active,
a:focus {
  outline: none;
}

u {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
