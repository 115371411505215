/*
 * Always set the map height explicitly to define the size of the div element
 * that contains the map.
 */
.map {
  height: 18rem;
  min-width: 16rem;
  border: 2px solid var(--color-blue);

  & > div {
    height: 100%;
    min-width: 16rem;
  }
}
