.mascot {
  img {
    cursor: pointer;
    transition: all 100ms ease-in-out;

    /* max screen */
    height: 12rem;
    margin-top: -0.5rem;
    padding: 0 3rem;
  }
}

/* tablet */
@media screen and (max-width: 768px) {
  .mascot {
    img {
      height: 10rem;
      margin-top: 0.5rem;
    }
  }
}

/* small */
@media screen and (max-width: 560px) {
  .mascot {
    img {
      height: 7rem;
      margin-top: 1rem;
    }
  }
}
