.Footer {
  background-color: var(--color-secondkit);
  padding: 2.4rem var(--side-padding) 2rem;

  .container {
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width);
    margin: 0 auto;
  }

  .footerInfo {
    p {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 2.1rem;
    }
  }

  /* small */
  @media screen and (max-width: 560px) {
    .container {
      text-align: center;
      margin: 0 auto;
      flex-direction: column-reverse;

      article {
        margin-bottom: 1.6rem;
      }
    }
  }
}
