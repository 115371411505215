@use '../theme/reset' as *;

html {
  font-size: 62.5%; // rem:px 1:1
  height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  font-family: var(--font-base);
  font-size: var(--font-size-h4);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ContainerFluid {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;
}

.ContentWrapper {
  position: relative;
  flex: 1 0 auto;
  width: 90%;
  max-width: var(--max-width);
  margin: 0 auto 6rem;
  // padding: 0 var(--side-padding);

  /* tablet */
  @media screen and (max-width: 768px) {
    // border: 2px solid yellow;
  }

  /* small */
  @media screen and (max-width: 560px) {
    width: 100%;
    padding: 0 var(--side-padding);
  }
}
