.leaflet-popup-content-wrapper,
.leaflet-tooltip {
  color: var(--color-blue);
  font-family: var(--font-base);
  font-size: 1.1rem;
  font-weight: 600;
  /* position: absolute; */
  /* padding: 4px; */
  /* background-color: rgba(0, 0, 0, 0.4); */
  /* border: 0 solid #000; */
  /* white-space: nowrap; */
  /* -webkit-user-select: none; */
  /* -moz-user-select: none; */
  /* -ms-user-select: none; */
  /* user-select: none; */
  /* pointer-events: none; */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.4); */
}
