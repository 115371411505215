.Header {
  background-color: var(--color-blue-dark);
  // border: 2px solid greenyellow;

  /* tablet */
  @media screen and (max-width: 768px) {
    // border: 2px solid yellow;
  }

  /* small */
  @media screen and (max-width: 724px) {
    // border: 2px solid red;
  }

  .topHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: var(--max-width);
    padding: 0.2rem var(--side-padding);
    margin: 0 auto;
    background-color: var(--color-blue-dark);
    height: 2.9rem;

    p {
      color: var(--text-color-white);
      font-size: 1.3rem;
      opacity: 0.7;
    }

    @media screen and (max-width: 724px) {
      display: none;
    }
  }

  header {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  .headerWrap {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}

.Header.shrink {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;

  @media screen and (max-width: 699px) {
    .topHeader {
      /*
      justify-content: flex-end;

      p:first-child {
        display: none;
      }
      */
    }
  }

  header {
    nav {
      height: 6rem;
    }

    // .mascot {
    ul > div {
      // margin-top: -2rem;
    }

    img {
      height: 8rem;
      margin: 0;

      @media screen and (max-width: 724px) {
        height: 7rem;
        margin-top: 1rem;
      }
    }
  }
}
