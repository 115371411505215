.socialIcon {
  width: 1.5rem;
  opacity: 0.7;
  margin: 0 0.5rem;
  transition: opacity 300ms ease-in-out;

  &.large {
    width: 2.6rem;
    height: 2.6rem;
    margin: 0 1rem;
  }

  &:hover {
    opacity: 1;
  }
}
