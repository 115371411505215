.navItem {
  display: inline-flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0.5rem 1.5rem;
  color: var(--text-color-white);
  opacity: 0.6;

  &.active,
  &:hover {
    opacity: 1;
  }

  &.active {
  }

  &:hover {
    transition: opacity 0.3s ease-in-out;
  }

  .label {
    display: flex;
  }

  /* tablet */
  @media screen and (max-width: 768px) {
  }

  /* small */
  @media screen and (max-width: 560px) {
    font-size: 1.5rem;
  }
}
