.pageNotFound {
  max-width: 70rem;
  padding: 3rem 1rem;
  margin-bottom: 3rem;

  .head {
    margin-bottom: 2rem;
  }

  .h2,
  .h3 {
    font-family: var(--font-header);
    color: var(--color-gray-dark);
    font-weight: 600;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    padding-bottom: 1rem;
  }

  .h2 {
    color: var(--color-blue);
    font-size: var(--font-size-h2);
    line-height: var(--line-height-h2);
    margin-bottom: 2rem;
  }

  .h3 {
    font-size: var(--font-size-h3);
  }

  a {
    text-decoration: underline;
  }
}
