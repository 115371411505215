.button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  text-align: center;
  border: 1px solid var(--theme-white);
  padding: 0.7rem 1.6rem;
  margin: 0 0.5rem;
  min-height: 3.6rem;
  min-width: 3.6rem;
  color: var(--text-color-white);
  background-color: var(--theme-darkblue);
  border-radius: 0.4rem;
  font-weight: 700;
  font-size: 1.4rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0 1px 0 0;

  &:hover {
    background: var(--theme-blue-hover);
    outline: 1px solid transparent;
  }
}
