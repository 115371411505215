nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ul {
    display: flex;

    li {
      display: flex;
    }
  }
}

.router {
}

.internationalizer {
}

.NavBtn {
  padding: 0 0.2rem;
  cursor: pointer;
  align-self: center;
}
