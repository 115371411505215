main {
  h1 {
    text-align: center;
    padding: 3rem 0;
  }

  &.shrink {
    padding-top: 8.5rem;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    button {
      color: var(--text-color);
    }

    .left,
    .right {
    }

    .left {
      height: 100%;
      margin-bottom: 3rem;

      article {
        padding-top: 3rem;
      }
    }

    .right {
      & > div {
        margin-bottom: 6rem;
      }

      .meetUs {
      }
      .tweetFeed {
      }
    }

    article {
      margin-bottom: 3rem;

      h2 {
        padding: 0 0 1.5rem;
      }

      p {
        padding-bottom: 1.5em;
      }

      @media (min-width: 981px) {
      }
    }

    @media (min-width: 981px) {
      flex-direction: row;

      display: flex;
      .left {
        width: 65%;
        margin-right: 5.5%;
      }
      .right {
        width: 35%;
      }
    }
  }
}
