.joinUs {
  text-align: center;
  padding: 4rem 6rem;
  background-color: #002f6d;
  margin-bottom: 1.5rem;

  p,
  h2 {
    color: white;
  }

  a {
    color: #f5ee42;
  }

  /* small */
  @media screen and (max-width: 425px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
